import React from "react";
import { graphql } from "gatsby";
// ******** Components ********
import Layout from "../components/Layout";
import HomePageHero from "../components/shared/HomePageHero";
import Resources from "../components/shared/Resources";
import SpeakWithAgent from "../components/speakWithAgent";
import HomeCalculator from "../components/homeCalculator";
// import Seo from "gatsby-plugin-wpgraphql-seo";
// import { customSchema } from "../components/shared/customSchema";
// import SeoDataFetcher from "../components/SeoDataFetcher.js";
import MHIOGIPlaceholder from "../assets/images/mhi-ogi-placeholder.jpg";
import MetaTags from '../components/MetaTags'; // Import your new component

const ObamacareCalculator = ({ data, location }) => {
  const { obamacareCalc, rankMathMeta } = data;

  const imageUrl = obamacareCalc?.featuredImage?.node?.localFile?.publicURL
      ? `${process.env.GATSBY_SITE_URL}${obamacareCalc.featuredImage.node.localFile.publicURL}`
      : `${process.env.GATSBY_SITE_URL}${MHIOGIPlaceholder}`;

  const pageUrl = `https://wordpress.myhealthinsurance.com${location.pathname}`;

  return (
    <Layout location={location}>
      <MetaTags rankMathMeta={rankMathMeta} imageUrl={imageUrl} />
      {/*<Seo*/}
      {/*  post={obamacareCalc}*/}
      {/*  postSchema={customSchema(obamacareCalc.seo.schema.raw)}*/}
      {/*/>*/}
      {/*<SeoDataFetcher url={pageUrl} />*/}
      <div className="obamacare-calculator">
        <HomePageHero
          image={obamacareCalc?.featuredImage}
          mobileImage={obamacareCalc?.mobileFeaturedImage?.mobileFeaturedImage}
        >
          <h1>{obamacareCalc?.title}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: obamacareCalc?.obamacareCalculator?.headerContent,
            }}
          />
        </HomePageHero>
        <section className="obamacare-calculator-intro-section">
          <div className="container is-widescreen">
            <h2>{obamacareCalc?.obamacareCalculator?.introTitle}</h2>
            <div
              className="intro-content"
              dangerouslySetInnerHTML={{
                __html: obamacareCalc?.obamacareCalculator?.introText,
              }}
            />
          </div>
        </section>
        <HomeCalculator location={location} />
        <Resources
          title="Learn More About Health Insurance"
          resources={obamacareCalc?.obamacareCalculator?.selectedArticles}
        />
        <SpeakWithAgent />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query obamacareCalculatorPage($id: String!, $pathname: String) {
    obamacareCalc: wpPage(id: { eq: $id }) {
      ...PageContent
      obamacareCalculator {
        articleSectionTitle
        fieldGroupName
        headerContent
        introText
        introTitle
        selectedArticles {
          ...PostPreviewContent
        }
      }
    }
    rankMathMeta: wpApiMeta(pathname: { eq: $pathname }) {
      metaTags
    }
  }
`;

export default ObamacareCalculator;
